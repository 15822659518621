import React from 'react';

import { MicrositeHeader } from '@latitude/microsite-header';

const DATA = [
  {
    id: 'home',
    label: 'Home',
    trackId: 'nav-home',
    href: '/credit-cards/gem-visa/'
  },
  {
    id: 'interest-free-offers',
    label: 'Interest Free offers',
    trackId: 'nav-interest-free-offers',
    href: '/interest-free/find-an-offer/'
  },
  {
    id: 'where-to-shop',
    label: 'Where to shop',
    trackId: 'nav-where-to-shop',
    href: '/interest-free/find-a-store/'
  },
  {
    id: 'balance-transfer',
    label: 'Balance Transfer',
    trackId: 'nav-balance-transfer',
    href: '/credit-cards/gem-visa/balance-transfer/'
  },
  {
    id: 'faqs',
    label: 'FAQs',
    trackId: 'nav-faqs',
    href: '/credit-cards/gem-visa/faqs/'
  },
  {
    id: 'help',
    label: 'Help',
    trackId: 'nav-help',
    href: 'https://latitudefs.zendesk.com/hc/en-au/'
  }
];
const APPLY_NOW_URL = "https://cards.latitudefinancial.com/gem";

function GemMicrositeHeader(props) {
  return (
    <div
      css={`
        margin-top: 0 !important;
        background-color: #f8f8f8;
        [class^='MicrositeHeader__HeaderPlaceholder'] {
          height: 0;
        }
        .microsite-header {
          z-index: 2000 !important;
        }
      `}
    >
      <MicrositeHeader
        data={DATA}
        ctaHref={APPLY_NOW_URL}
        microSiteName="Gem Visa card&reg;"
      />
    </div>
  );
}

export default GemMicrositeHeader;
