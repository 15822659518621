import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import Section from '@latitude/section';
import { Link } from '@latitude/link';
import { Heading4 } from '@latitude/heading';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';

import Layout from '@/components/layout';
import AchPageHeaderSection from './_ach-page-header';
import GemMicrositeHeader from '../_gem-microsite-header';

const buttonHref = '/credit-cards/gem-visa/addcard/ach-form/';

function AchAdditionalLandingPage(props) {
  const [urlButton, setUrlButton] = useState(null);

  function fetchButtonHref() {
    if (window?.location?.search) {
      const queryParameters = new URLSearchParams(window.location.search);
      const applicationId = queryParameters.get('applicationid');

      if (applicationId) {
        return buttonHref + '?applicationid=' + applicationId;
      }
    }
    return null;
  }

  useEffect(() => {
    const href = fetchButtonHref();
    setUrlButton(href);
  }, []);

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/gem-visa/addcard/ach/"
          />
          <meta
            name="description"
            content="Welcome to the Latitude 28° Global Platinum Mastercard® family"
          />
          <title>Additional Card Holder Welcome | Gem Visa card</title>
        </Helmet>

        <AchPageHeaderSection />
        <GemMicrositeHeader />

        <Section>
          <Heading4 css={{ color: '#1964B4' }}>
            Welcome to the Gem Visa card&reg; family
          </Heading4>

          <div className="row">
            <div className="col-md-8">
              <p>
                Simply complete the online form to confirm your personal details
                such as full name, address and date of birth. You can use
                documents such as:
              </p>
              <p>
                <ul>
                  <li>Australian Driver's Licence</li>
                  <li>Australian Passport</li>
                  <li>Medicare card</li>
                </ul>
              </p>

              <p>
                Once complete your new Gem Visa card will be sent to the account
                holders address within 5-7 working days.
              </p>
            </div>
            <div className="col-md-4">
              {urlButton ? (
                <Link
                  button={BUTTON_STYLE.SECONDARY}
                  css={{
                    margin: '0 auto',
                    width: 'fit-content'
                  }}
                  href={urlButton}
                >
                  Complete today
                </Link>
              ) : (
                <div
                  css={{
                    backgroundColor: '#777',
                    color: '#fff',
                    borderRadius: '4px',
                    padding: '12px 24px',
                    margin: '0 auto',
                    width: 'fit-content',
                    textAlign: 'center'
                  }}
                >
                  Missing Application ID
                </div>
              )}
            </div>
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default AchAdditionalLandingPage;
